import { jsonClone } from '../../helper/util.mjs';

export const LEFT_JOIN = 'LEFT';
export const RIGHT_JOIN = 'RIGHT';
export const INNER_JOIN = 'INNER';
export const CommonColumnDef = [
    {
        key: 'id',
        dataType: 'INTEGER',
        primaryKey: true,
        autoIncrement: true,
        label: 'ID',
        editable: false,
    },
    {
        key: 'create_time',
        dataType: 'INTEGER',
        default: "strftime('%s', 'now')",
        label: '创建时间',
        inputType: 'datetime',
        editable: false,
    },
    {
        key: 'update_time',
        dataType: 'INTEGER',
        default: "strftime('%s', 'now')",
        label: '更新时间',
        inputType: 'datetime',
        editable: false,
    },
];

export function fillColumns(table) {
    const { columns } = table;
    CommonColumnDef.forEach(columnDef => {
        const foundColumn = columns.find(column => column.key === columnDef.key);
        if (!foundColumn) {
            if (columnDef.key === 'id') {
                columns.unshift(jsonClone(columnDef));
            } else {
                columns.push(jsonClone(columnDef));
            }
        }
    });
    columns.forEach(column => {
        if (column.inputType === undefined) {
            column.inputType = 'text';
        }
    });
    return table;
}

export function createTableDef(tableDef) {
    return fillColumns(tableDef);
}
