export function jsonClone(json) {
    return JSON.parse(JSON.stringify(json));
}

const executionQueue = [];
let isProcessing = false;

/**
 * 链式执行异步函数，保证按调用顺序依次执行
 * @param {Function} asyncFn - 需要执行的异步函数
 */
export async function chainExec(asyncFn) {
    executionQueue.push(asyncFn);

    if (!isProcessing) {
        isProcessing = true;
        while (executionQueue.length > 0) {
            const currentFn = executionQueue.shift();
            try {
                await currentFn(); // 顺序执行每个异步函数
            } catch (error) {
                console.error('链式执行出错:', error);
            }
        }
        isProcessing = false;
    }
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
